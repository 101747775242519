/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";
import { getPhysician, updatePhysician  } from '../../actions/payActions'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class PhysicianInforation extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{Source:"ACT Physician Portal",PaymentMethod:""},
            login:true,
            Name:"",
            PhysicianId:"",
            Email:"",
            Phone:"",            
            loading:true,
            checked:"",
            form_disable:true,
            form_edit:false,
            
        }
    }

    componentDidMount() {      
        var physician_id = localStorage.getItem('physician_id'); 
        this.setState({'physician_id':physician_id})
        if(physician_id){
            this.props.getPhysician(physician_id,this.props.history);

        }       
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps ----------',nextProps); 

        if(nextProps.page_name.page_name){
            
            this.state.formData['MedicalLicenseNumber'] = nextProps.page_name.page_name.Medical_License_Number__c
            this.state.formData['PhysicianId'] = nextProps.page_name.page_name.Physician_ID__c
            this.state.formData['ShippingEmail'] = nextProps.page_name.page_name.Shipping_Email__c 
            this.state.formData['FirstName'] = nextProps.page_name.page_name.FirstName
            this.state.formData['LastName'] = nextProps.page_name.page_name.LastName
            this.state.formData['Email'] = nextProps.page_name.page_name.Email
            this.state.formData['Phone'] = nextProps.page_name.page_name.Phone
            this.state.formData['ClinicName'] = nextProps.page_name.page_name.Account.Name
            this.state.formData['City'] = nextProps.page_name.page_name.MailingCity
            this.state.formData['Country'] = nextProps.page_name.page_name.MailingCountry
            this.state.formData['zipcode'] = nextProps.page_name.page_name.MailingPostalCode
            this.state.formData['State'] = nextProps.page_name.page_name.MailingState
            this.state.formData['Street'] = nextProps.page_name.page_name.MailingStreet
            // this.state.formData['E_Signature_Physician_Agreement'] = nextProps.page_name.page_name.E_Signature_Physician_Agreement__c
            // this.state.formData['Physician_Form_Agreement_Hidden'] = nextProps.page_name.page_name.Physician_Form_Agreement_Hidden__c
            this.setState({Form_Agreement:nextProps.page_name.page_name.Physician_Form_Agreement_Hidden__c})
            this.setState({Name:nextProps.page_name.page_name.Name})
            this.setState({Email:nextProps.page_name.page_name.Email})
            this.setState({Phone:nextProps.page_name.page_name.Phone})
            this.setState({PhysicianId:nextProps.page_name.page_name.Id})
            this.setState({loading:false})
            localStorage.setItem('physician_id',nextProps.page_name.page_name.Id);
            this.setState({checked:nextProps.page_name.page_name.Physician_Form_Agreement_Hidden__c})

            // console.log('form data', this.state.formData)

            // console.log('physician_id ------ ',localStorage.getItem('physician_id') )
            this.setState({physician_id:localStorage.getItem('physician_id')})
            
                     
   
            
            // this.setState({transactionId:nextProps.page_name.page_name.transactionId});           
            // let element = 
          }
          
          if(nextProps.page_name.physician_info){
            this.setState({update_response:nextProps.page_name.physician_info})
            this.setState({loading:false})
          }

    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
        let field_id = e.target.id
        this.setState({field_id:e.target.value})
    }

    handleCheck = (e) => {        
        this.state.formData[e.target.id] = !this.state.checked; 
    }

    submit = (e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure you want to update this information?</h1>
                  <p></p>
                  <button onClick={onClose}>No</button>
                  <button
                    onClick={() => {
                        this.onSubmit(e);
                      onClose();
                    }}
                  >
                    Yes
                  </button>
                </div>
              );
            }
          });
        // confirmAlert({
        //   title: 'Confirm to submit',
        //   message: 'Are you sure to do this.',
        //   buttons: [
        //     {
        //       label: 'Yes',
        //       onClick: () => this.onSubmit(e)
        //     },
        //     {
        //       label: 'No',
        //       onClick: () => alert('Click No')
        //     }
        //   ]
        // });
    };

    editForm = (e) =>{
        this.setState({form_edit:true})
        this.setState({form_disable:false})
    }

    cancleForm = (e) =>{
        this.setState({form_edit:false})
        this.setState({form_disable:true})
    }

    

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["City"]){
            formIsValid = false;
            errors["City"] = "Please enter City";
            let element = document.getElementById("City");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["State"]){
            formIsValid = false;
            errors["State"] = "Please enter State";
            let element = document.getElementById("State");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Please enter Country";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["Street"]){
            formIsValid = false;
            errors["Street"] = "Please enter Street";
            let element = document.getElementById("Street");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["zipcode"]){
            formIsValid = false;
            errors["zipcode"] = "Please enter zipcode";
            let element = document.getElementById("zipcode");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // if(isNaN(fields["zipcode"])){
        //     formIsValid = false;
        //     let postalcode = valid.postalCode(fields["zipcode"]);
        //     // console.log('postalcode',postalcode);
        //     errors["zipcode"] = "Invalid Zip Code";
        //     let element = document.getElementById("zipcode");                
        //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        //     window.scrollTo({top: y, behavior: 'smooth'});
        //     element.className = "form-control error"
        //  } 


        

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Cannot be empty";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(fields["Email"] && !validator.isEmail(fields["Email"])) {
            formIsValid = false;
            errors["Email"] = "Invalid email.";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }


        if(!fields["ShippingEmail"]){
            formIsValid = false;
            errors["ShippingEmail"] = "Cannot be empty";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(fields["ShippingEmail"] && !validator.isEmail(fields["ShippingEmail"])) {
            formIsValid = false;
            errors["ShippingEmail"] = "Invalid shipping email.";
            let element = document.getElementById("ShippingEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }
        

        if(!fields["MedicalLicenseNumber"]){
            formIsValid = false;
            errors["MedicalLicenseNumber"] = "Cannot be empty";
            let element = document.getElementById("MedicalLicenseNumber");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        

        if(!fields["Phone"]){
            formIsValid = false;
            errors["Phone"] = "Cannot be empty";
            let element = document.getElementById("Phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(isNaN(fields["Phone"]) && fields["Phone"]){
            formIsValid = false;          
            errors["Phone"] = "Please insert number only";
            let element = document.getElementById("Phone");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
         } 
        
        if(!fields["ClinicName"]){
            formIsValid = false;
            errors["ClinicName"] = "Cannot be empty";
            let element = document.getElementById("ClinicName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["LastName"]){
            formIsValid = false;
            errors["LastName"] = "Cannot be empty";
            let element = document.getElementById("LastName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["FirstName"]){
            formIsValid = false;
            errors["FirstName"] = "Cannot be empty";
            let element = document.getElementById("FirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }



        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            this.setState({form_edit:false})
            this.setState({form_disable:true})
            const newList = this.state.formData;
            // console.log("newlist=>", newList);
            this.props.updatePhysician(newList, this.props.history);
            this.setState({loading:true})
            }

        

    }

    render() {
        const { formData,Name,Email,Id,Phone } = this.state;
        // console.log('formData -----', formData)

        if(!sessionStorage.getItem('physician_id') || sessionStorage.getItem('physician_id')==null) {           
            return <Redirect to="../" />
          } 
        return (
            <MainLayout>
                <Header />
                { (this.state.loading)?
                <div class="loading-div">
                    <div class="loading-img"><img src="images/spinner.svg" alt="" /></div>
                </div>:""}

                <div className="main-content">
                    <Left activeMenu={"physician-information"}/>  

                    <div className="right-section">
                    <div className="pagesubheader-wrap">
                        <div className="pagesubheader-row">
                            <div className="page-subheader-col-left">
                                <div className="title-avatar">
                                    <img src="images/physician-icon.svg" alt="" />
                                </div>
                                <div className="title-main">
                                    <span className="title-sub-text">Physician Portal</span>
                                    <h1>Physician Information</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    { (this.state.update_response)?
                    <div className="col-md-12">
                        <h5 style={{paddingTop:"10px"}}><i> Physician information successfully updated  ! </i></h5> 
                        </div>:
                    <div className="inner-content-area">

                   


                    <form className="needs-validation" noValidate >
                    <div className="form-wrap">
                        <div className="row">
                        {(!this.state.form_edit)?
                        <div className="col-md-12">
                            <div className="form-group">                            
                            <div className="field-col">
                                <input type="button" className="btn btn-secondary mr-2" onClick={this.editForm} placeholder="" value="Edit" />
                            </div>                            
                        </div>
                        </div>:""}
                        
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Physician ID  </label>
                            <div className="field-col">
                                <input type="text" className="form-control" placeholder="" value={formData.PhysicianId} disabled />
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">First Name <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="FirstName" className="form-control" value={formData.FirstName} placeholder="" onChange={this.onChange} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Last Name <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="LastName" className="form-control" value={formData.LastName} placeholder="" onChange={this.onChange} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Clinic <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" className="form-control" id="ClinicName" onChange={this.onChange} placeholder="" value={formData.ClinicName} disabled={this.state.form_disable}  />
                                <span style={{color: "red"}}>{this.state.errors["ClinicName"]}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Email <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="Email" className="form-control" value={formData.Email} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Shipping Email <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="ShippingEmail" className="form-control" value={formData.ShippingEmail} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["ShippingEmail"]}</span>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Medical License Number <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="MedicalLicenseNumber" className="form-control" value={formData.MedicalLicenseNumber} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["MedicalLicenseNumber"]}</span>
                            </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Phone Number <span className="required">*</span></label>
                            <div className="field-col">
                                <input type="text" id="Phone" className="form-control" value={formData.Phone} onChange={this.onChange} placeholder="" disabled={this.state.form_disable} />
                                <span style={{color: "red"}}>{this.state.errors["Phone"]}</span>
                            </div>
                            </div>
                        </div>
                        
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Street <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Street" className="form-control" onChange={this.onChange} value={formData.Street} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["Street"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">City <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="City" className="form-control" onChange={this.onChange} value={formData.City} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["City"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">State <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="State" className="form-control" onChange={this.onChange} value={formData.State} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["State"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Zip Code <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="zipcode" className="form-control" placeholder="" onChange={this.onChange} value={formData.zipcode} disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["zipcode"]}</span>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                <label className="col-form-label">Country <span className="required">*</span></label>
                                <div className="field-col">
                                    <input type="text" id="Country" className="form-control" onChange={this.onChange} value={formData.Country} placeholder="" disabled={this.state.form_disable} />
                                    <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                </div>
                                </div>
                            </div>


                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Address</label>
                            <div className="field-col">
                                <input type="text" id="Address" className="form-control" onChange={this.onChange} placeholder="" />
                                <span style={{color: "red"}}>{this.state.errors["Address"]}</span>
                            </div>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label pt-0">Physician Agreement Complete</label>
                            <div className="field-col custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="E_Signature_Physician_Agreement"  onChange={this.handleCheck} defaultChecked={this.state.Form_Agreement} disabled />
                                <label className="custom-control-label" for="E_Signature_Physician_Agreement"></label>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6 mob-none">
                            <div className="form-group">&nbsp;</div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="form-group">
                            <label className="col-form-label">Shipping Addresses </label>
                            <div className="field-col">
                                <textarea className="form-control" placeholder=""></textarea>
                                <div className="custom-control custom-checkbox mt-3">
                                <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                <label className="custom-control-label" for="customCheck2">Saved Shipping Addresses</label>
                                </div>
                            </div>
                            
                            </div>
                        </div> */}
                        </div>
                        {(this.state.form_edit)?
                        <div className="col-md-6">
                        <div className="button-row">
                        <button type="button" className="btn btn-outline-primary mr-2" onClick={this.cancleForm}>Cancel</button>
                        <button type="button" onClick={this.submit} className="btn btn-primary">Save</button>
                        </div></div>:""}
                    </div>
                    </form>                    
                    </div>
                    }
                    </div>
                    

                </div>

            </MainLayout>
        );
    }
}

PhysicianInforation.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    getPhysician:PropTypes.func.isRequired,    
    updatePhysician:PropTypes.func.isRequired,    
};


const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {getPhysician,updatePhysician  })(PhysicianInforation);

// export default PhysicianInforation;